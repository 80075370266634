@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



/* ====================================================
    Fonts
   ==================================================== */

@font-face {
	font-family: "Karbon-Regular";
	src: url("../fonts/karbon-regular-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-regular-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  url("../fonts/karbon-regular-webfont.otf") format("opentype"), /* Open Type Font */  url("../fonts/karbon-regular-webfont.svg") format("svg"), /* Legacy iOS */  url("../fonts/karbon-regular-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  url("../fonts/karbon-regular-webfont.woff") format("woff"), /* Modern Browsers */  url("../fonts/karbon-regular-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Light";
	src: url("../fonts/karbon-light-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-light-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  url("../fonts/karbon-light-webfont.otf") format("opentype"), /* Open Type Font */  url("../fonts/karbon-light-webfont.svg") format("svg"), /* Legacy iOS */  url("../fonts/karbon-light-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  url("../fonts/karbon-light-webfont.woff") format("woff"), /* Modern Browsers */  url("../fonts/karbon-light-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Medium";
	src: url("../fonts/karbon-medium-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-medium-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  url("../fonts/karbon-medium-webfont.otf") format("opentype"), /* Open Type Font */  url("../fonts/karbon-medium-webfont.svg") format("svg"), /* Legacy iOS */  url("../fonts/karbon-medium-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  url("../fonts/karbon-medium-webfont.woff") format("woff"), /* Modern Browsers */  url("../fonts/karbon-medium-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Bold";
	src: url("../fonts/karbon-bold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-bold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  url("../fonts/karbon-bold-webfont.otf") format("opentype"), /* Open Type Font */  url("../fonts/karbon-bold-webfont.svg") format("svg"), /* Legacy iOS */  url("../fonts/karbon-bold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  url("../fonts/karbon-bold-webfont.woff") format("woff"), /* Modern Browsers */  url("../fonts/karbon-bold-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Semibold"; 
	src: url("../fonts/karbon-semibold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-semibold-webfont.eot?#iefix") format("embedded-opentype"), 
	/* IE6-IE8 */  url("../fonts/karbon-semibold-webfont.otf") format("opentype"), /* Open Type Font */  url("../fonts/karbon-semibold-webfont.svg") format("svg"), /* Legacy iOS */  url("../fonts/karbon-semibold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  url("../fonts/karbon-semibold-webfont.woff") format("woff"), /* Modern Browsers */  url("../fonts/karbon-semibold-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

/* ====================================================
    Global
   ==================================================== */
body {
	background-image:linear-gradient(#ededed, #ededed);
}


sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
  font-size: .7em;
    
    &.sm{font-size: .5em;}

    &.symbol { 
      font-size: 1.2em;
      top: 0em;
    }
    &.sup-daggers{
      font-size: 1.1em;
      top: -0.05em;
    }
    &.sup-section{
      top: -0.5em;
    }
    &.sup-parallel{
      font-size: 11px;
      top: -0.4em;
      font-family: Arial, sans-serif !important;

    }
     
}

.footnote {
  sup {
    &.sup-daggers{
      // font-size: 10px;
      // top: -0.3em;
      margin-left: -1px;
    }
    &.sup-section{
      top: -0.2em;
    }
    &.sup-parallel{
      top: -0.2em;
      font-size: 12px;
      font-family: Arial, sans-serif !important;
    }
  }
}
sup.upsize { top:0em; font-size: 1.1em; }
.unbold{ 
        font-family: "Karbon-Medium"!important;
                            } 
sub { 
  top: 0.4em; 
}
p{
    line-height: 1.1em;
    margin-bottom: .8%;
}

a, a:hover, a:focus{
    color:$blue;
   text-decoration: underline;
    
}
a.not-underline, a.not-underline:hover, a.not-underline:focus { text-decoration:none; }
.uppercase{ text-transform: uppercase;}
strong{ 
    font-family: "Karbon-Bold";
    font-weight: normal;
}
.black{color: $black!important;}
h1{
        font-family: "Karbon-Semibold";
        color: $yellow;
        line-height: .7em;
        font-size: 32px;
        margin-bottom: 0px;
        @media print, screen and (min-width: 64em){ // large desktop view
          margin: 10px 0px ;
          font-size: 42px;
          line-height: .8em;
        }
}

h2{
    font-family: "Karbon-Regular";
    color: $blue;
    font-size: 30px;
    line-height: 1em;
    letter-spacing: -.01em;
    text-transform: uppercase;
    margin: 20px 0px 10px 0px;
    .sm{  
        font-size: 20px;
        @media print, screen and (min-width: 39.99875em){ // ipad view
          margin: 20px 0px ;
          font-size: 48px;
        }
        @media print, screen and (min-width: 64em){ // large desktop view
          margin: 20px 0px ;
          font-size: 48px;
        }
    }
    @media print, screen and (min-width: 64em){ // large desktop view
          margin: 20px 0px ;
          font-size: 48px;
        }
   
}

h3{
    color: $white;
    font-size: 17px;
    line-height: .8em;
    font-family: "Karbon-Medium";
    margin: 0px;
     @media print, screen and (min-width: 39.99875em){ // ipad view
          font-size: 22px;
          line-height: 1em;
           margin: 10px 0px ;
    }
    @media print, screen and (min-width: 64em){ // large desktop view
          font-size: 22px;
          line-height: 1em;
           margin: 10px 0px ;
          
        }
   
   }

 h4{
     font-family: "Karbon-Bold";  
     font-size: 24px;
     text-transform: uppercase; 
     color:$blue;
    }

 h5{
     font-family: "Karbon-Semibold";  
     font-size: 22px;
     text-transform: uppercase; 
     line-height: 1em;
    }

h6{
    font-family: "Karbon-bold";
    font-size: 1.1em;
    line-height: .9em;
     @media print, screen and (min-width: 39.99875em){ // ipad view
          line-height: 1em;
        }
}

h7{
    font-family: "Karbon-bold";
    font-size: 18px;
    line-height: 1em;
}

h8{
    font-family: "Karbon-Semibold";  
     font-size: 20px;
     line-height: .8em;
}



.lowercase{
    text-transform:lowercase;
}
hr{

    border-bottom: 1px solid $blue;
    margin:20px 0px;
    @media print, screen and (min-width: 64em){ // large desktop view
         margin: 40px 0px;
        }
    &.mg20{
        margin: 20px 0px; 
        
    }
    &.mgT20{
        margin: 20px 0px 0px 0px; 
    }
    &.wt{border-bottom: 1px solid $white;}

}

.symbol-greaterLess {
  font-size: 1.3em;
  font-family: inherit !important;
  color:inherit !important;
  vertical-align: -0.06em;
}

.sm-icon{
  height: 40px;
  margin-bottom: 3%;
}
.vAlign .cell {
  margin-top: auto;
  margin-bottom: auto;
}

.grid-container{
	/*margin-top: 20px;*/
   // background-color: $white;
    
}

 .box-pad{
        padding: 10px ;
        @media print, screen and (min-width: 64em){ // large desktop view
         padding: 20px 30px 20px 30px;
        }
      a{
          color:$yellow;
         // text-decoration: underline;
        }
    }
.p-pad{
    padding: 20px ;
        @media print, screen and (min-width: 64em){ // large desktop view
         padding: 20px;
        }

}

ol li{
   font-family: "Karbon-Bold";  
    span{
        font-family:"Karbon-Medium";
      
    }
}


ul li{
    line-height: 1.3em;
}

ul.bullets {
    margin-left:.7rem;
    margin-bottom:5px;
    li{
	list-style-type:none;
	margin-bottom:0px;
        line-height: 1em;
    }
}
ul.bullets li::before{
	content: "\2022";
  	color: $yellow;
  	 font-family: "Karbon-Bold";  
	font-size:20px;
	position:absolute;
	//margin-top: -8px;
	margin-left: -12px;
}
ul.bullets li:last-child{
	margin-bottom:0;
}

ul.sub-bullets {
    margin-left: 13px;
    li{
	    margin-bottom:3px;
    }
}
ul.sub-bullets li::before{
	content: "\25E6";
	font-size: 12px;
	margin-top: 0px;
}

.white-box{ background-color:#FFFFFF}
.white-link{ color:$white;
    &:hover{color:$yellow;}
}
.white-txt{
    color: $white;
}
.blue-txt{
    color: $blue!important;
}
.yellow-txt{
     color: $yellow!important;
}
.black-txt{
    color: $black!important;
}
.light-gray-box{
    background-color:#ededed
}
.blue-txt-box{
	background-color:$blue!important;
    color:$white!important;
}
.gray-txt-box{
	background-color:$dark-gray!important;
    color:$white!important;
   
}
.yellow-txt-box{
	background-color:$yellow!important;
    color:$blue;
}

.blue-bg{background-color:#005989;}
.gray-bg{background-color:#c7c8ca;}
.litgray-bg{background-color:#dcddde;}

.noIndent{ text-indent: 0!important;}
.indent{text-indent: -6px!important; margin-left: 6px;}

.blue-border{ 
    border:1px solid $blue;
    border-top:none;
}
.blue-border-all{    
    border:1px solid $blue;
    }


.borderT20{ border-top: 20px solid #fff}
.borderB20{ border-bottom: 20px solid #fff}

.borderR{
    border-right: none;
    border-bottom: 1px solid $blue;
    &.white{ border-color: $white!important;}
    @media print, screen and (min-width: 39.99875em){// medium - for ipad view
         border-right: 1px solid $blue;
           border-bottom: none;
        
    }
        
     @media print, screen and (min-width: 64em){ // large desktop view
           border-right: 1px solid $blue;
           border-bottom: none;
        }
}
.borderL{
    border-left: 0px solid $blue;
        @media print, screen and (min-width: 64em){ // large desktop view
           border-left: 1px solid $blue
        }
}

.borderB{
    border-bottom: 1px solid $blue;
    &.white{
        border-color:$white;
    }
}

.marginT20{margin-top: 20px;}
.marginT40{margin-top: 40px;}
.marginT60{margin-top: 60px;}
.marginT80{margin-top: 80px;}
.marginB20{margin-bottom: 20px;}
.marginB40{margin-bottom: 40px;}
.marginB0{margin-bottom: 0px;}
.margin0{margin:0!important;}

.padding20{padding:20px;}
.padding40{padding:20px 40px;}
.padding60{padding:60px;}
.padding5{padding:5px;}
.noBpadding{padding-bottom: 0px!important;}
.noRpadding{padding-right: 0px!important;}
.nopadding{padding:0px!important;}

.infobox{ 	
    background:url('../img/icon_info.svg') no-repeat;
    background-size: 40px auto;
    background-position: 10px 10px;
    background-color: $blue;
    font-family: "Karbon-Semibold";
    
    padding: 10px 5px 10px 60px;
    color: $white;
    
    &.pcd{
        margin: 20px 0px;
        line-height: 1.1em;
    }
    @media print, screen and (min-width: 39.99875em){ // ipad view
           background-size: 30px auto;
           background-position: 20px 10px;
        }
    
        @media print, screen and (min-width: 64em){ // large desktop view
            background-size: 50px auto;
            background-position: 15px 15px;
             background-position: 18px 15px;
             padding: 15px 10px 10px 85px;
    
        }
    
}

.yellow_btn{
        
        font-family: "Karbon-Semibold";
        text-transform: uppercase;
        text-decoration: none;
        text-align: left;
        font-size: 14px;
        color:$light-blue!important;
        background-color: $yellow!important;
        margin-right: 10px;
            &.share{
                
                background:url('../img/icon_share.svg') no-repeat;
                background-size: 20px auto;
                background-position: right 12px top 8px;
                
                padding-right:50px;
                width:270px;
              
    
            }
            &.go{  
                background:url('../img/icon_go.png') no-repeat;
                background-size: 10px auto;
                background-position: right 12px top 50%;
                
                padding-right:30px;
                
            }
            &.download{
                background:url('../img/icon_download.png') no-repeat;
                background-size: 20px auto;
                background-position: right 12px top 50%;
               
                padding-right:30px;
            }
           &:hover{
              background-color: $light-gray;
              box-shadow: 1px 1px 5px 2px #727272;
              color:$blue;
            }
       &.nogray:hover{
              
              color:$blue;
            }
            
        @media print, screen and (min-width: 39.99875em){ // ipad view
          //margin-left: 10px;
        }
    
        @media print, screen and (min-width: 64em){ // large desktop view
        margin-left: 0px;
        }
    }
.footnote{
        font-size: 1em; 
        margin: 5px 0px 25px 0px;
        padding:10px 20px 0px 0px;
        line-height: 1.1em;
        clear:both;
        word-break: break-word;
         @media print, screen and (min-width: 39.99875em){ // ipad view
           margin:0px ;
            &.sm{ font-size: .9em;}    
        }
    
        @media print, screen and (min-width: 64em){ // large desktop view
           margin:0px 0px 0px 0px;
           &.sm{ font-size: .9em;}
        }
        p{
         margin-bottom: 3px;
         text-indent: -.26em;
         text-align: left!important; 

         
           
        }
    .sindent{
        text-indent: -.1em;
        margin: .5em 0px;
    
    }
    }    


/* ====================================================
    Navigation Area
   ==================================================== */
.sticky-container{
    z-index:10;
    position: fixed;
    width:100%;
    top:0
}
.nav-bar {
	
    color: $white;
	padding-top: 19px 0px ;
	/*box-shadow: 0px 3px 6px rgba(0,0,0,0.16);*/
	transition: 0.4s;	  
	.accordion-menu .is-accordion-submenu a{
		padding:0.5rem 1rem;
	}
    .topbar{ 
            height: 60px;
            background-color: $blue;
            z-index: 500;
        }
    .is-dropdown-submenu{
        ul{list-style: none; margin-left: 0px;}
    }
    #desktop-menu{ 
        width:100%; 
        position: relative;
        
        .product_home{
            background:url('../img/icon_plus_blue.svg') no-repeat;
            background-size: 20px auto;
            background-position: right 14% top 46%;
            background-color: $white;
            padding-left: 15px;
            display: flex;
            align-items: center;  
            float:left;
            width:14%;
            height: inherit;
            color:$blue!important;
            text-decoration: none;
            @media print, screen and (max-width: 1024px){
              width: 16%;
            }

            &.on{ 
                background:url('../img/icon_close_blue.svg') no-repeat;
                background-size: 20px auto;
                background-position: right 14% top 46%;
                background-color: $white;
                }
            
        }
        
        #menu-top{
            //width:100%;
            font-size: 14px;
            //float: right;
            line-height: 60px;

            @media print, screen and (max-width: 1024px){
                &.menu a {
                  padding-left: 0.4rem;
                  padding-right: 0.4rem;
                }
            }
            
            li{ 
                margin-left: 5px;
            }
            li:first-child{
                margin-left: 30px;
                @media print, screen and (max-width: 1024px){
                  margin-left: 15px;
                }

            }
            a{
                color: $white; line-height: 32px; 
            }
            a:hover, a:focus, .on{  color: $yellow; text-decoration: none;  }

            }  
        .product_menu{ 
            
            position: absolute;
            top:0px;
            left:0px;
            color:$black;
            width:602px;
            height:auto;
            font-size: 12px;
            line-height: 11px;
            z-index: 400!important;
             background-color: $light-gray;
            .flR{float:right;}
             .flL{float:left;}
            a{
                color:$black; 
                text-decoration: none;
            }
            &.hide{
                display:none;
            }
            img{
                width:160px;
                clear: right;
                }
            .btn{
               
                 //box-shadow: 1px 1px 5px grey;
                padding:30px;
                width:300px;
                }
            .btn:nth-child(4), .btn:nth-child(5) {
               // padding-top:0px;
            }
        }

    }
    
 
    
    .mob_topbar{
         display: flex;
         align-items: center;    
         justify-content: center; 
         font-size: .9em;
        height:30px!important;
        a{  width:50%;
            text-align:center;
            text-decoration: none;
        }
    }

    .menu-icon{
        float: right;
        width:35px;
        margin-top: 20px;
        margin-right:20px;
    }
    
    .rem-logo{
	    width:150px;
        height: 100%;
        float: left;
        margin-left: 0px;
        background:url('../img/logo_Remicade.svg') no-repeat;
        background-size: 150px auto;
        background-position: left 0 top 45%;
        @media print, screen and (min-width: 64em){ // large desktop view
          background-size: 200px auto;
          width:200px;
          margin-left: 10px;
        }
       }
    
    .graybar{
          background-color: rgba(0, 36, 58, 0.7);
          height: 60px;
          z-index: 200;
         @media print, screen and (min-width: 64em){ // large desktop view
          height: 82px;
        }
        .gray-menu-content{
          float: right;
            
            .menuA{
                line-height: 60px;
                color:$white;
                font-family: "Karbon-Semibold";
                font-size: 1em;
                padding:0.7rem 2rem
                    

            }
             li a{
                &::after{
                   border-color: transparent transparent transparent; 
                }
                &:hover {
                    background-color: $white;
                    color:$light-blue;
                    text-decoration: none;
                   
                }
                &:focus{
                    background-color: $white;
                    color:$light-blue;
                }
                &.active{
                    //background-color: $white;
                    color:$yellow;
                }
             }
             li:hover > a {
                    background-color: $white;
                    color:$light-blue;
                   
                }
            .menuB_group{ 
                    width:100%!important;
                    a{ 
                        color:$black;
                        
                        &:hover{
                             color:$light-blue;  
                             background-color: $white;
                        }
                        &.active{
                        //background-color: $white;
                        color:$light-blue;
                        }        
                        
                      }
                .is-active > a{
                     color:$light-blue; 
                     background-color: $white;
                }
                
                ul li{ width:250px;}
                }
            
            }
    }
    .menu.vertical li ul li a{ 
        padding:.7rem 1rem !important
    ;}

}

.product_overlay{
        position: absolute;
        width:100vw;
        height:100vh;
        background-color: rgba(10, 10, 10, 0.45);
        z-index: 300;
    &.hide{
        display: none;
    }
}

.sticky.is-anchored{ 
    z-index:500;
    top:0px!important;
    position: fixed;
}
 
.nav-bar.is-stuck {
    .graybar{
        background: rgba(255, 255, 255, 1);
        .menuA{
                color:$blue;
            &:hover{
                box-shadow: 1px 1px 5px grey;
            }
         }
        .rem-logo{
            background:url('../img/logo_Remicade_blue.svg') no-repeat;
        }
        .menu-icon::after{
            background-color: $blue;
            -webkit-box-shadow: 0 7px 0 $blue, 0 14px 0 $blue;
            box-shadow: 0 7px 0 $blue, 0 14px 0 $blue;
        }
        
    }
    

}



.menu.vertical{  // for mobile menu
    background-color: $white;
    line-height: 2em;
    box-shadow: 1px 1px 5px grey;
    margin-left: 0px!important;
    li {
       font-family: "Karbon-Semibold";
       border-bottom: 1px solid #2492da; 
        
        &:last-child{ border: none;}

        .product_desc{
          color: #0a0a0a;
          font-size: 12px;
          line-height: 1.0em;
          font-weight: 300;
          margin-top: -22px;

          a:focus{
            color: #0a0a0a;
          }
        }

        &.blue-menu{
          background-color: $blue;
          border-bottom:none;

          a {
            color: $white;
          }
        }
        ul li{
            a{
            color:$black;
                padding:1rem 1rem!important;
                 &:hover {
                   // background-color: $blue;
                    color:$light-blue;
                }     
                
            }
        }
    }
    
}
.accordion-menu a {
  padding:1rem 1rem!important;

  &:hover {
    text-decoration: none;
  }
}

#mobile-menu {
  max-height:80vh;
  overflow-y:scroll;
  box-shadow: 0px 7px 10px rgba(0,0,0,0.4);
  position:fixed;
  z-index:1001;
  width: 100%;
  top: 89px;
 
}


/* ====================================================
   hero
   ==================================================== */


.hero{
    
    position: relative;
    border-bottom: 25px solid $white;
    background-color: $white;
    min-height: 465px;
    margin-top: 0px;
    background-repeat: no-repeat;
      @media print, screen and (min-width: 39.99875em){ // ipad view
         margin-top: 30px;
        }
    @media print, screen and (min-width: 64em){
         margin-top: 50px;
        }
       
    .headers{
        position: absolute; 
        bottom: 15%;
         @media print, screen and (min-width: 39.99875em){ // ipad view
                bottom: 3%;
        }
          @media print, screen and (min-width: 64em){ // large desktop view
               bottom: 5%;
        }
        h1{
            font-size: 42px;
            text-transform: uppercase;
             @media print, screen and (min-width: 64em){ // large desktop view
              width:100%;   
             // font-size: 42px;   
            }
            
            @media print, screen and (min-width: 39.99875em){ // ipad view
                width:100%;
                margin:3px 0px;
               
            }
        }
        
        h3{
            
            margin:10px 0px 0px 0px;
            @media print, screen and (min-width: 64em){ // large desktop view
           
                margin:3px 0px;
        }
            @media print, screen and (min-width: 39.99875em){ // ipad view
                margin:2px 0px;

        } 
            
        }
       

    }
    .hero-logo{
        width:50%;
        margin-top: 20px;
        @media print, screen and (min-width: 39.99875em){ // ipad view
          width:20%;
           
        }
        
        @media print, screen and (min-width: 64em){ // large desktop view
          width:200px;
          
        }
        &.sm{ 
            width:30%;
            
            @media print, screen and (min-width: 39.99875em){ // ipad view
            width:16%;
            }
        
            @media print, screen and (min-width: 64em){ // large desktop view
              width:160px;
            }
        }
    }
    
    .hfoot{
        margin: 5px;
        font-size: 11px;
        line-height: 1em;
        color:$white;
        text-indent: -3px;
        @media print, screen and (min-width: 39.99875em){ // ipad view
               
               font-size: 14px;
            }
        @media print, screen and (min-width: 64em){ // large desktop view
               width:30%;
               margin-top: 15px;
               font-size: 14px;
            }
}
    
    &.sub {
    min-height: 375px;
    margin-top:0px;
      .jnjlogo{
       // margin-top: 20px;

        img {
          width: 162px
        }
      }
      .headers {
        padding-top: 140px;
      }

    }
}

.line-logo .hero.sub .headers {
  padding-top: 170px;
}

.twoline-logo .hero.sub .headers {
  padding-top: 155px;
}



/* ====================================================
   pop up reveal
   ==================================================== */

.reveal{
         background-color: $light_gray;
         margin: 0px auto;
         @media print, screen and (min-width: 64em){ // large desktop view
             width:65vw;
             padding: 3% 6%;
             min-width: 950px!important;
            
        }
    &.wide{
        @media print, screen and (min-width: 64em){ // large desktop view
             width:85vw;
             padding: 3% 6%;
               min-width: 950px!important;
        }
      
    }
    &.nobg{
        height: 100vh;
        background-color: transparent;
        border: none;
         &:focus {
            outline: none;
               }
    }
}



#pop_dosing{
    p{  
        font-family: "Karbon-Regular"; 
        line-height: 1.1em;
        span{ 
           color:$blue;
           font-family: "Karbon-Medium";
        }
        @media print, screen and (min-width: 64em){ // large desktop view
        }
    }
    
}

.dosing{
    font-family: "Karbon-Regular"; 
    margin-top: 40px;
     @media print, screen and (min-width: 64em){ // large desktop view
          .pushR{
                margin-right:22px;
            }
        }
        
    
    .box{
         border: 1px solid $blue;
         padding:10px;
    }
    .icon2{
        margin-top: 0px;
        @media print, screen and (min-width: 64em){ // large desktop view
          margin-top: 0px;  
        }
        
    }
    .icon img, .icon2 img{
        width:30%;
        margin-top: -8%;
        @media print, screen and (min-width: 39.99875em){ // ipad view
            
        }
        @media print, screen and (min-width: 64em){ // large desktop view
            
        }
    }
    .blue-txt{
        font-family: "Karbon-Semibold"; 
    }
    .subtxt{
        font-size: 18px;
        line-height: 1.1em;
        text-align: center;
    }
    .follow_by{ 
                height:100px;
                background:url('../img/dosing_devid_mob.png') no-repeat;
                background-size: auto 80px;
                background-position: left 50% top 5%;
          @media print, screen and (min-width: 39.99875em){ // ipad view
               height:200px;
                width:100px;
                background:url('../img/dosing_devid.png') no-repeat;
                background-size: 90% auto;
                background-position: left 50% top 45%;
              
        }
            @media print, screen and (min-width: 64em){ // large desktop view
                height:250px;
                width:120px;
                background:url('../img/dosing_devid.png') no-repeat;
                background-size: 90% auto;
                background-position: left 50% top 45%;
                }
    }
    
}


/* ====================================================
    Landing page
   ==================================================== */
#home{
     .clinicmenu, .index_b2{
            position: absolute;
            bottom:0px;
            font-family: "Karbon-Semibold"; 
            font-size:14px;
            text-align: left;

        
        }
    
    .index_b1, .index_b2{
        width:320px; 
        height:40px;
        padding-top:14px;
        
            @media print, screen and (min-width: 39.99875em){ // ipad view
                  margin-bottom: 0px;
                  width:290px; 
            }
         @media print, screen and (min-width: 64em){ // ipad view
                  margin-bottom: 0px;
                  width:480px; 
            }
        
            
    }
    .index_b1{ 
        padding-left:15px;
        
    }
    .index_b2{ 
        padding-top:7px;
       
        @media print, screen and (min-width: 64em){ // ipad view
                 padding-top:14px;
            }
    }
    .cL, .cR{
         position: relative;
         margin: 10px 0px;
         min-height: 170px;
            @media print, screen and (min-width: 39.99875em){ // ipad view
                  min-height: 120px;
            }
             @media print, screen and (min-width: 64em){ // large desktop view
             min-height: 280px;
             margin: 40px 0px;
            
            }
       
        
    }    
    
    .clinicmenu{
         bottom:40px;
         @media print, screen and (min-width: 39.99875em){ // ipad view
                  bottom:0px;
            }
        li.is-active{
            background-color: $white;
        }
        
           
        @media screen and (max-width: 39.9375em){
           .is-dropdown-submenu >li .is-dropdown-submenu{
                position:inherit;
                 }
            }
            
        }
        .dosingmenu{
          // bottom:29px;
            position: absolute;
           font-family: "Karbon-Semibold"; 
            background-color: $yellow;
           text-transform: none;
           color:$light-blue;
            font-size:14px;
            text-align: left;
            width:290px;
            left:22px;
            bottom:15px;
            margin-bottom: 16px;
           
            @media print, screen and (min-width: 39.99875em){ // ipad view
                 left:16px;
                width:306px;
            }
             @media print, screen and (min-width: 64em){ // ipad view
                 left:25px;
                
            }
            @media screen and (max-width: 39.9375em){
                
               .is-dropdown-submenu >li .is-dropdown-submenu{
                position:inherit;
                 }
            }
    }
    
    
    
    }


    
    
    

.blue-txt-box-p{
    min-height: 300px!important;
    padding:40px 30px 0px 30px;
    position: relative;
    p{
        line-height: 1.2em;
    }
    img{
        height:75px;
        margin-bottom:10%;
    }
    .yellow_btn{
       
        background-color: $yellow;
        text-transform: none;
        color:$light-blue;
        height:44px;
        line-height:12px;
        
    
    }
    .yellow_btn{
         position: absolute;
         bottom:15px;
         width:290px;
         left:22px;
         right: 22px;
         margin-right: auto;
         margin-left: auto;

         &.lineup {
          line-height:20px;
         }

        @media print, screen and (min-width: 39.99875em){ // ipad view
                   left:16px;
                   right: 16px;
                   width:306px;
                   line-height:20px;
                   margin-right: auto;
                   margin-left: auto;
            }
         @media print, screen and (min-width: 64em){ // ipad view
                   left:25px;
                   right: 25px;
                   margin-right: auto;
                   margin-left: auto;               
              
            }
        
             
    }
    .dline{
        line-height:14px!important;
        padding-top:.65em;
        }

    .dosingmenu {
      margin-right: auto;
      margin-left: auto;
      right: 22px;
    }
    
}

.clinicmenu.menu , .dosingmenu.menu {
    > li.is-dropdown-submenu-parent > a{
        padding-right:2rem;
     &:after{
        display: block;
        width: 0;
        height: 0;
        content: '';
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #1779ba;
        right: 12px;
        left: auto;
        margin-top: -9px;
    }
   }
}

.dropdown.yellow{
   
    
            li a{
                    color:$black;
                    background-color: $white;
                    text-decoration: none;
                    box-shadow: 1px 1px 5px grey;
                    &:hover{
                        background-color: $white;
                        color:$light-blue!important;
                         
                    }
                     
            } 
            li:hover > a {
                    background-color: $white;
                    box-shadow: 1px 1px 5px grey;
                    color:$light-blue;
                     &:after{
                        display: block;
                        width: 0;
                        height: 0;
                        content: '';
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid #1779ba;
                        right: 12px;
                        left: auto;
                        margin-top: -3px;   
                         
                    }
                }
          //  li >a::after{display:none;}
            ul{ width:100%; }
            .yel{ 
                    background-color: $yellow; 
                    color:$light-blue;
                    padding:10px 0px 0px 10px;
                    height:44px;
            }
    .submenu li a{
                font-size: .85em;
                line-height: 1em;
                 @media print, screen and (min-width: 39.99875em){ // ipad view
                   font-size: 1em;
                }
        
                &:after{ 
                     content: none!important;
                     }
        }
    
}



/* ====================================================
    clinical_information
   ==================================================== */
.eyebrow{
    margin-top: 20px;
    line-height: 1.1em;
    color:$light-blue!important;
}


/* ======= tables  ================== */
 table{
       color:$blue;
       font-size: .9em;  
       @media print, screen and (min-width: 64em){ // large desktop view
           font-size: 1em;   
          }
         thead{ 
              background-color: inherit;
              border:none;
                th{
                    font-family: "Karbon-Semibold";
                    border-right: 1px $black solid; 
                    border-bottom: 1px $black solid; 
                    padding:0.5rem 0.625rem 0.125rem;
                    //margin: 0;
                    @media print, screen and (min-width: 64em){ // large desktop view
                          padding:0.1rem 0.625rem 0.125rem;
                      }
                    font-weight: normal;
                   // text-align: center;
                    color:$blue;
                      span{
                         font-family: "Karbon-Regular";   
                        }
                     &:last-child{
                                border-right:none;
                            } 
                    }
                  }
                tr{
                        td{ 
                            text-align: center;
                            border-right: 1px $black solid; 
                            border-bottom: 1px $black solid; 
                            &.whiteB{ border-bottom: 1px $white solid; }
                            &:first-child{
                                text-align: left;
                                font-family: "Karbon-Semibold";
                                line-height: 1em;
                                padding: 5px;
                            }
                            &:last-child{
                                border-right:none;
                            } 
                        }
                    &:last-child{
                        td{
                                border-bottom:none;
                            }
                     } 
                    
                    }
                
     .blue-txt-box{ 
         font-family: "Karbon-Semibold"; 
     }
     
     
     
}
.toleft{
        text-align: left!important;
        //margin-left:3px;
    }
.table_scroll{
         width:inherit;
         overflow-x:scroll;
    
         @media print, screen and (min-width: 64em){ // large desktop view
                          width:auto;
                          overflow-x:hidden;
                      }
     }

tbody{
    background-color: inherit;
    border: none;
    &.unbold{
        tr td{
            font-family: "Karbon-Medium";
        }
    }
}

.table_header{ 
    position: relative;
    text-align: center; 
    font-size: 1.1em; 
    width:100%;
    min-height:40px;
    padding:9px 0px 5px 0px;
    text-transform: uppercase;
    margin-top: 5.5%;
     @media print, screen and (min-width: 64em){ // large desktop view
                 font-size: 1.2em;         
                      }
    &:after{
        content: '';
        position: absolute;
        margin-top: 30px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $blue;
        
        @media print, screen and (min-width: 64em){ // large desktop view
                      
                      }
    }
}

.table_box{
    margin-top: 9px;
    background-color: #f8f8f8;
    border: $blue solid 1px;
    padding: 10px 3% 10px;
    @media print, screen and (min-width: 64em){ // large desktop view
        padding: 2% 5% 25px;
    }
    &.wide{ 
        
       @media print, screen and (min-width: 64em){ // large desktop view
        padding: 2% 5% 25px;
        }
     
    
    }
    .toleft{
        margin-left:3px; 
    }
    
    p{  
                text-align: center;
                &.left{
                    text-align: left;
                    margin-left: 1%;
                    color:$blue;
                }
               span{
               color:$blue;
               font-family: "Karbon-Bold";
               text-transform: uppercase;
                
                }
                &.number{ 
                    font-size: 75px;
                    line-height: .8em;
                    margin-bottom: 0px;
                    color:$blue;
                    
                   span{
                    font-family: "Karbon-Bold";
                     //  letter-spacing: .1em;
                      span{ font-size: 60px!important;
                       text-transform:none!important;}
                    }
                }
    }
    
    tbody th, tbody td{
        padding:0.1rem 0.5rem 0.1rem
    }
    thead th{ 
        text-align: center; 
        line-height: .9em;
        padding:0.3rem 0.5rem 0.3rem;
            &:first-child{ 
               text-align: left; 
                }    
    }
    ul li{
        font-size: 1.1em;
    }
}

.number.tight{ 
    letter-spacing: -2px;
    &.cl{  //CD page
        margin-top: 0px;
          @media print, screen and (min-width: 39.99875em){ // ipad view
          margin-top: 20px;

          }
    }
    &.cr{
        margin-top: 15px;
          @media print, screen and (min-width: 39.99875em){ // ipad view
          margin-top: 0px;

          }
    }
}


.blue_band{
        background-color:$blue;
        color:$white;
        padding:10px;
        margin-top:20px;
        font-family: "Karbon-SemiBold";
        &.down{ 
            margin-top: 45px;
        }
 
}

/* ======= tabs  ================== */
.tabgroup{margin-top: 40px;}

.tabs{
    background: none;
    border: none;
    
    .tabs-title { 
   // border: 5px solid #ededed ;
  //  width:50%;
      padding:0px;
      
     a{
        height:30px;
        width:100%;
        margin-left: 10px;
        background: #bacacf;
        color:#000000!important;
        text-align: center;
       // padding:12px 8px 12px 8px;
        padding:0px;
        font-size: 1.1em;
        line-height: 35px;
        font-family: "Karbon-Medium";
        text-transform: uppercase;
        text-decoration: none;
        outline: none;
         &:last-child{ margin: 0px!important;}
          
       // margin:1px;
          @media print, screen and (min-width: 39.99875em){ // ipad view
            // margin:5px;
             font-size: 1.1em;
            //  padding:7px 8px 4px 8px;
        }
         @media print, screen and (min-width: 64em){ // large desktop view
            // margin:5px 3px;
             font-size: 1.1em;
            // padding:7px 8px 4px 8px;
        }
         
        
      }
     a:focus, a[aria-selected='true']{
           background :#005889;
            color:$white!important;
            border: none;
        }
        a:hover{
           text-decoration: none;
        }

        
    }
    
    .is-active{
       // margin:0px 0px 5px 0px;
        background-color: $blue;
        position: relative;
        z-index:2;
        a{
            line-height: 40px;}
    }
    .doubleL{
        padding-right: 8px;
        &.is-active {
        &:after{
            content: '';
            position: absolute;
            width: 105%;
            height:20px;
            margin-top: -1px;
            background:url('../img/tabarrow_mob.jpg') no-repeat;
            background-size: 100% 100% ;
            background-position: right 0px bottom 0px;
            @media print, screen and (min-width: 39.99875em){ // ipad view
                 width: 102%;
                 height:20px;
                 background:url('../img/tabarrow.jpg') no-repeat;
                 background-size: 100% 100% ;
                 background-position: right 0px bottom 0px;
               

            }
             @media print, screen and (min-width: 64em){ // large desktop view
                  width: 102%;
                  height:20px;
                  background:url('../img/tabarrow.jpg') no-repeat;
                  background-size: 100% 100% ;
                  background-position: right 0px bottom 0px;

            }

          
            clear: both;
            
            }
       
      }  
    }
    .doubleR{
        padding-left: 8px;
        &.is-active{
        &:after{
            content: '';
            position: absolute;
            
            width: 105%;
            height:20px;
            left:-8px;
            margin-top: -1px;
            background: url("../img/tabarrowR_mob.jpg") no-repeat;
           
            @media print, screen and (min-width: 39.99875em){ // ipad view
                width: 102.5%;
                height:20px;
                margin-top: 0px;
                background: url("../img/tabarrowR.jpg") no-repeat;
                
             
            }
            @media print, screen and (min-width: 64em){ // large desktop view
                 width: 102.2%;
                 height:20px;
                 margin-top: 0px;
                background: url("../img/tabarrowR.jpg") no-repeat;
                 
            }
            background-size: 100% 100%;
            background-position: right 0px bottom 0px;
            clear: both;
            
            }
       
        
        }
    }
   .triL{
        padding-right: 5px;
        &.is-active{
        &:after{
            content: '';
            position: absolute;
            margin-top: -4px;
            width: 105%;
            height: 20px;
            background: url("../img/triL_mob.jpg") no-repeat;
            background-size: 100% 97%;
            background-position: right 0px bottom 0px;
            clear: both;
             @media print, screen and (min-width: 39.99875em){ // ipad view
                 width: 102.7%;
                 margin-top: -1px;
                 background: url("../img/triL.jpg") no-repeat;
                 background-size: 100% 97%;
                 background-position: right 0px bottom 0px;
            }
             @media print, screen and (min-width: 64em){ // large desktop view
                width: 102.3%;
                 margin-top: -1px;
                background: url("../img/triL.jpg") no-repeat;
                background-size: 100% 97%;
                background-position: right 0px bottom 0px;
             }
            
            
           

            
            }
            }
        }
    .triM{
        padding: 0px 5px;
        &.is-active{
        &:after{
            content: '';
            position: absolute;
            left:-5px;
            width: 109%;
            height: 20px;
            margin-top: -3px;
            background: url("../img/triM_mob.jpg") no-repeat;
            background-size: 100% 100%;
            background-position: right 0px bottom 0px;
            clear: both;
             @media print, screen and (min-width: 39.99875em){ // ipad view
                  width: 104.9%;
                  left:-6px;
                  margin-top: -1px;
                  background: url("../img/triM.jpg") no-repeat;
                  background-size: 100% 100%;
                  background-position: right 0px bottom 0px;
            }
             @media print, screen and (min-width: 64em){ // large desktop view
                 width: 104.9%;
                 margin-top: -1px;
                 left:-6px;
                 background: url("../img/triM.jpg") no-repeat;
                 background-size: 100% 100%;
                 background-position: right 0px bottom 0px;
             }
           
            
            }  
        }
    }
    .triR{
        padding-left:5px;
        &.is-active{
        &:after{
            content: '';
            position: absolute;
            margin-top: -4px;
            left:-5px;
            width: 104.5%;
            height: 20px;
            background: url("../img/triR_mob.jpg") no-repeat;
            background-size: 100% 97%;
            background-position: right 0px bottom 0px;
            clear: both;
             @media print, screen and (min-width: 39.99875em){ // ipad view
                  margin-top: -2px;
                 width: 102.7%;
                 left:-6px;
                 background: url("../img/triR.jpg") no-repeat;
                 background-size: 100% 97%;
                background-position: right 0px bottom 0px;
            }
             @media print, screen and (min-width: 64em){ // large desktop view
                  margin-top: -1px;
                   left:-6px;
                  width: 102.4%;
                 background: url("../img/triR.jpg") no-repeat;
                    background-size: 100% 97%;
                    background-position: right 0px bottom 0px;
             }
           

            }
    }
    }
  

}

.tabs dd > a, .tabs .tab-title > a{
    outline: none;
}
.tabs-content{
  
   border:none;
   background: none; 
   margin-top: 0px;
    @media print, screen and (min-width: 64em){ // large desktop view
                margin-top: 10px;
             }
     @media print, screen and (min-width: 39.99875em){ // ipad view
                 margin-top: 0px;
        }
    
    .tabs-panel{
        
        padding: .5rem .7em;
      
        width:100%;
        @media print, screen and (min-width: 64em){ // large desktop view
                padding:1em;
                padding-top: 0px;
             }
        @media print, screen and (min-width: 39.99875em){ // ipad view
                padding: .7rem 1rem; 
        }
        .footnote{
            margin:0px;
            padding:0px;
           @media print, screen and (min-width: 64em){ // large desktop view
                 margin: 10px 10px 0px 20px;
             }
            }
        .tab-box{
             background:#f8f8f8;
             border:1px $blue solid;
             padding:10px;
             
             display: flex;
             align-items: center;    
             justify-content: center;  
             position: relative;   
             @media print, screen and (min-width: 64em){ // large desktop view
                padding:40px 5px 25px 5px;
             }
            
            
            .c-header{
                text-align: center;
            } 
            .toleft{
                margin-left: 0px;
                @media print, screen and (min-width: 64em){ // large desktop view
                margin-left: 19px;
             }
            }
            img{ 
            
               display: block;
               margin: 0px;
              @media print, screen and (min-width: 64em){ // large desktop view
              // margin: -20px 10px 0px 10px;
             }
            }
            p, .table_box .header {  
                text-align: center;
                &.left{
                    text-align: left;
                    margin-left: 1%;
                    color:$blue;
                }
               span{
               color:$blue;
               font-family: "Karbon-Bold";
               text-transform: uppercase;
                
                }
                &.number{ 
                    font-size: 86px;
                   // line-height: .8em;
                    margin-bottom: 0px;
                    color:$blue;
                span{
                    font-family: "Karbon-Bold";
                  
                    }
                }
                .big{
                    font-size: 20px;
                    font-family: "Karbon-Medium";
                    span{
                       font-family: "Karbon-Bold";  
                    }
                }
            }
           
    }
     .columns{ 
            padding:20px;
            p{
            @media print, screen and (min-width: 64em){ // large desktop view
              margin:0px 5%;
             }
            }
        }

}
    }

   
    #panelB2{
        img{
            margin: -30px 0px 0px 0px;
            @media print, screen and (min-width: 64em){ // large desktop view
                margin: -60px 0px 0px 0px;
             }
        }
    }
    #panelC1{
        .moveup{ 
            @media print, screen and (min-width: 64em){ // large desktop view
            margin-top:-36px;
             }
            
        }
    }


.tabs-content, .table_box{ 
    .header{
       width:100%;
        margin: 0px auto;
        margin-top: 20px;
        sup{font-family: "Karbon-Medium";}
         @media print, screen and (min-width: 39.99875em){ // large desktop view
           width:80%;
             }
    }
     .midtxt{
         width:90%;
         text-align:center;
         margin:0 auto 3% auto;
         @media print, screen and (min-width: 64em){ // large desktop view
           width:50%;
             }
         &.wide{
             width:100%;
         }
        }
 
}
.subtxt{
            padding:0 3%;
            p{
             text-align: left!important;
             margin: .5rem 0px .1em 0px;

            }
            .blue{ 
                color:$blue;
                font-size: 16px;
                font-family: "Karbon-Bold"

            }
        ul {
          list-style: none; /* remove the default */
            li{
                text-indent:-15px
            }
            li::before { /* add the new bullet point */
            display: inline-block;
            content: '';
             -webkit-border-radius: 0.375rem;
            border-radius: 0.375rem;
            height: 0.35rem;
            width: 0.35rem;
            margin-right: 0.5rem;
            margin-bottom:3px;
            background-color: $blue;
            }
        
    }
            }
.table_box .subtxt{  padding:0px 0px 10px 0px;  }


.page_content{
    .cL, .cR{
        
        @media print, screen and (min-width: 64em){ // large desktop view
            margin: 30px 0px;
        }
    
   }
   .Ybtns{
       p{
        &:first-child{ 
            margin-top:20px;
             @media print, screen and (min-width: 64em){ // large desktop view
            margin-top:80px;
            }
        }
           }
    } 
    &.dosing_content, &.access_content{
        h2{
            padding: 10px 0px 0px 0px; 
           // margin: 10px 0px 10px 0px;   
            @media print, screen and (min-width: 64em){ // large desktop view
            //padding: 20px 60px 20px 0px;
            
            }
            
        }
    }    
}



.clinical{
      .addmarginT{ 
         margin-top: 20px;
        @media print, screen and (min-width: 64em){ // large desktop view
            
              margin-top: 112px;
              &.first{ margin-top: 90px;}
              &.pcd{margin-top: 82px;}
            }

        }
        .yellow_btn.share{
            float:left;
            @media print, screen and (min-width: 64em){ // large desktop view
             float:right;
            }

        }
    }

/* ====================================================
    Dosing
   ==================================================== */

#administration-instructions{
    .step{
        margin-bottom: 30px;
        
        &.addBborder{
            border-bottom: 1px solid #005889;
            
            @media print, screen and (min-width: 64em){ // large desktop view
             border-bottom: none;
            }
        }
        @media print, screen and (min-width: 64em){ // large desktop view
          margin-bottom: 50px;
        }
    }
    
}

.dosingadmin{
    li{
        list-style: disc;
        color:$yellow;
        &:last-child{
            margin-bottom: 30px;
        }
        span{
            margin-top:5px;
            color:$white;
        }
    }
}

.videotxt{
    padding:18px 20px 0px 20px; 
    height:81px;
     line-height: 1em;
     @media print, screen and (min-width: 39.99875em){ // ipad view
         padding:10px 20px 0px 20px; 
         font-family: "Karbon-SemiBold";
         font-size: .8em;
         line-height: 1em;
         height:41px;
        }
     @media print, screen and (min-width: 64em){ // ipad view
         padding:15px 0px 0px 20px;
         height:41px;
         line-height: 1.15em;
        }
}
.viewvideo{ 
    width:100%;
    padding-top:9px 10px 0px 10px;
    height:40px;
   
    .button{
        width:100%;
        text-decoration: none;
        color:$light-blue;
        background:url('../img/icon_go.png') no-repeat;
        background-size: 10px auto;
        background-position: right 12px top 50%;
        background-color: $yellow;
        padding:.95em 1em 0.78em;
    	
    	span{
    		position:relative; top:2px;
    	}    
    }
    
    @media print, screen and (min-width: 39.99875em){ // ipad view
        
         font-family: "Karbon-SemiBold";
         font-size: .7em;
         line-height: 1em;
        }
    @media print, screen and (min-width: 64em){ // large desktop view
         
        }
}

.calculator{
     font-family: "Karbon-SemiBold"!important;
     margin-bottom: 30px;
     // height:100%;
     padding-bottom: 0px;
    
     @media print, screen and (min-width: 64em){//  - desktop view
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    @media print, screen and (min-width: 39.99875em){ // ipad view
          padding-bottom: 0px;
         margin-bottom: 0px;
    }
    
    .header{
        padding: 1%;
        text-transform: uppercase;
        
   }
    .calrow{ 
        height:39px; width:100; 
        clear:both; margin-bottom: 1em;
         @media print, screen and (min-width: 40em){//  - desktop view
         margin-bottom: 1em;
        }
        
        
        @media print, screen and (min-width: 64em){//  - desktop view
         margin-bottom: 1.5em;
        }
        
    }
    //label{ color:$blue; clear: both; font-size: 1em;  float:left;}
    select{
        width:40%; 
        font-size:25px; 
        line-height: 25px;  
        border:$light-blue 1px solid; 
        float: left;  
        margin: 0;  
        &.sm{width:20%;}
    }
    .ctxt{
        line-height: 26px;
        margin-top: 8px; 
        color:$blue;
        float:left;
        
        &.long{
            width:60%;
           line-height: 1em;
            }
        &.shift{margin-left:5px;}
    }
    .footnote{
        font-family: "Karbon-Medium"; 
        font-size: .7em;
        margin: -10px 0px 10px 7px;

        

        &.sm{ font-size: .9em;}
         @media print, screen and (min-width: 64em){//  - desktop view
         
          margin: -10px 0px 25px 7px;
        }
    }
    .field{
            float:left;
            background-color: $white; 
            border:$light-blue 1px solid; 
            font-size: 25px;
            min-width:90px!important;
            height: 2.4375rem;
            margin: 0;
            padding: 0.15rem 1rem;
            color:$black;
        }
    .enter{
        margin-top: 0px;
        width:80%;
        letter-spacing: .2em;
         @media print, screen and (min-width: 39.9em){//  - ipad view
         margin-top: 20px;
        
        }
        
         @media print, screen and (min-width: 64em){//  - desktop view
         margin-top: 30px;
        
        }
    }
    
    h3{ 
        color:$black;
        margin-bottom: 20px;
    }
    .block{  
        width:95%;
        margin: 20px auto 45px auto;
        padding:0% ; 
        height:80%;
         @media print, screen and (min-width: 39.9em){//  - ipad view
          padding:5% 2% 0; 
          margin: 20px auto;
        
        }
        
         @media print, screen and (min-width: 64em){//  - desktop view
         padding:5% 2% 0; 
          margin: 20px auto;
        }
        
        
    }
    p {
        margin: 20px 0px;
        span{ color:$blue;
            
        }
    }
    
    .doseValBlock{clear: both;}
}


#dosing_RA{
    .studay_Start{
        width:290px;
    }
}

/* ====================================================
    ACCESS & SUPPORT & RESOURCES
   ==================================================== */


.plain-txt-box-p{
       
     @media print, screen and (min-width: 39.99875em){ 
        height: inherit;
         min-height: 430px!important;
        }
    
    @media print, screen and (min-width: 64em){ // large desktop view
        height: inherit;
         min-height: 320px!important;
        }
    padding:20px 25px 0px 25px;
    position: relative;
    .inner-box{
        min-height: 270px;
         @media print, screen and (min-width: 39.99875em){ 
             min-height: 430px;
        }
        @media print, screen and (min-width: 74em){ 
             min-height: 350px;
        }
    }
    .header{
        font-family: "Karbon-Bold";
        font-size: 1.15em;
        margin-bottom: 2px;
        }
    p{
        line-height: 1.2em;
        margin-bottom: .2em;
        &.lastrow {
            font-family: "Karbon-Semibold";
            span{
            
            font-size: .9em;
               
          }
        }
    }
    img{
        height:75px;
        margin-bottom:10%;
        &.sm{
            height:45px;
            margin-bottom:3%;
        }
    }
    .row2{
        margin-top: 10%;
    }
   
    .download{
        position: inherit;
        bottom:0px;
        @media print, screen and (min-width: 64em){ // large desktop view
             position: absolute;
            }
       @media print, screen and (min-width: 39.99875em){ // large desktop view
             position: absolute;
            }
    }

}
.rowpad{
     width:100%;
     @media print, screen and (min-width: 64em){ // large desktop view
             padding:0px 10px 0px 30px;
            }
}
.access_content{
        
        .yellow_btn{      
        width:80%;
        
        &.wide{
            width:95%;
             @media print, screen and (min-width: 64em){ // large desktop view
             width:95%;
            }
         }
        &.acces_c2{
            margin-top: 19px;
             @media print, screen and (min-width: 39.99875em){ // large desktop view
              margin-top: 0px;
            }
             
        }
       /* &.acces_c3{
            margin-top: 20px;
             @media print, screen and (min-width: 64em){ // large desktop view
            margin-top: 110px;
        }
        }
        &.acces_c4{
            margin-top: 5px;
            margin-bottom: 50px;
             @media print, screen and (min-width: 64em){ // large desktop view
            margin-top: 26px;
            }    
        }*/
            &.login{
                @media print, screen and (min-width: 64em){ // large desktop view
            float:right;
            }  
               
            }
        }
        
        .Gettingstarted_txt{
            padding-left: 0px;
            padding-top: 20px;
             @media print, screen and (min-width: 64em){ // large desktop view
             padding-top: 0px;
                    padding-left: 20px;
            }  
            
        }
        .icon_question{
            width:30%;
            padding: 2% 0px 5% 0px;
             @media print, screen and (min-width: 39.99875em){ // ipad view
              padding: 2%;
               width:100%;
               
            }  
            
            @media print, screen and (min-width: 64em){ // large desktop view
              padding: 10%;
                  width:100%;
            }  
        }
    .btn_downRes{
        width:80%;
        @media print, screen and (min-width: 64em){ // large desktop view
            width:35%
        }
    }
    
    }

.QAbtn{ 
    width:100%;
    text-align: left;
  
    background-color: $blue;
    font-size:1.2em;
    background:url('../img/icon_plus.svg') no-repeat;
   
    background-size: auto 25px;
    background-position: right 10px top 50%;
    background-color: $blue;
    text-indent: -13%;
    padding-left: 15%;
    
     &.on{
        background:url('../img/icon_minus.svg') no-repeat;
        background-size: auto 25px;
        background-position: right 10px top 50%;
        background-color: $blue;
         @media print, screen and (min-width: 64em){ // large desktop view
        width:100%;
        background-size: auto 30px;
        background-position: right 40px top 50%;
        background-color: $blue;
     
        }
    }
    
    
    @media print, screen and (min-width: 39.99875em){ // ipad view
       text-indent: 0;
         padding-left: 3%;
        }
    @media print, screen and (min-width: 64em){ // large desktop view
        width:100%;
        background-size: auto 30px;
        background-position: right 40px top 50%;
        background-color: $blue;
        text-indent: 0;

        padding-left: 3%;
        }
    
    span{
        font-family: "Karbon-Bold";
        font-size: 1.3em;
        color: $yellow;
        margin-right: 5px;
       @media print, screen and (min-width: 64em){ // large desktop view
        margin-right: 20px;
        }
        
    }
}
.dropdown-pane{
    position: relative;
    background-color: inherit;
    border:none;
    width: 100%;
    padding-left: 80px;
    z-index: 0;
    padding-left: 10px;
    @media print, screen and (min-width: 39.99875em){ // large desktop view
            padding-left: 60px;
        }
     @media print, screen and (min-width: 64em){ // large desktop view
        padding-left: 120px;
        }
    span{
        font-family: "Karbon-Bold";
        font-size: 1.3em;
        color: $blue;
        margin:0px 0px 0px 10px;
        line-height: 1em;
        float:left;
         @media print, screen and (min-width: 64em){ // large desktop view
            margin: 0px;
        }
    }
    .answerbox{ 
        margin-left: 35px;
        @media print, screen and (min-width: 64em){ // large desktop view
            margin-left: 40px;
        }
    }
}



.logo_carepath{
    width:60%;
    padding:0px 0px 10% 0px;
     @media print, screen and (min-width: 39.99875em){ // large desktop view
            padding:0% 20% 0% 0%;
            width:45%;
        }
     @media print, screen and (min-width: 64em){ // large desktop view
            padding:0% 20% 10% 0%;
           width:100%;
        }
}

.logo_infuse{
     width:60%;
    padding:0px 10% 10% 0;
     @media print, screen and (min-width: 39.99875em){ // large desktop view
            padding:0% 20% 0% 0%;
            width:70%;
        }
     @media print, screen and (min-width: 64em){ // large desktop view
            padding:0% 20% 10% 0%;
           width:70%;
        }
}


.CarePath_txt{
    margin-top:5%;
    font-size: 1.2em;
    @media print, screen and (min-width: 64em){ // large desktop view
            margin-top:0;
            padding-left: 20px;
            font-size: 1em;
           
        }
}
.infusion_txt{
      margin-top:3.5%;
}

.button.video{
    background-color:transparent!important;
    &:focus {
    outline: none;
   }
}

.privacy-table {
  tbody {
    border: none;
    background-color: #ededed;

    td{
      padding: 0 5px 0 0;
      vertical-align: top;
      border: none;
      text-align: left;
        color:#000;
        
    }
  }

  tbody tr:nth-child(even) {
    background-color: #ededed;
  }
}



/* ====================================================
    VIDEO
   ==================================================== */
.coverAll {
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
/* background-color: rgba(0,0,0,0.7);
   z-index: 2;*/
    cursor: pointer;
}
    
#brightcove-playervideo{
    position: absolute;
    top: 52%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}
.close_btn {
    position: absolute;
   top: 235px;
    right: 237px;
    font-size: 23px;
    font-weight: bold;
    color: #1C98F4;
    z-index: 1000;
}

div.video-slideimg {
    width: 100%;
    height:250px;
    float: left;
    background-color: $blue;
    padding: 12px 10px 5px 12px;
    // border-bottom: 3px solid #91A5B5;
    @media print, screen and (min-width: 39.99875em){ // large desktop view
             height: 106px;
        }
     @media print, screen and (min-width: 64em){ // large desktop view
              height: 86px;
        }
    
}
div.video-slideimg a.slideimg img:hover {
    border: 4px solid #FFD63C;
}

.video-cpcode {
  background-color: #005889; 
  font-size: 16px; 
  padding-left: 32px; 
  padding-bottom: 5px;
}
     
.bc-player-ry4UU_4zb_default {  // size of the video
    
    width: 350px ;
    height: 260px ;          
     @media print, screen and (min-width: 39.99875em){ // large desktop view
             width: 700px ;
             height: 360px ;  
        }
     @media print, screen and (min-width: 64em){ // large desktop view
             width: 934px ;
            height: 460px ;  
        }
    
    
        }       
div.video-slideimgtext {
    width: 50%;    
    height:33%;
     @media print, screen and (min-width: 39.99875em){ // large desktop view
             width: 16.66%;
             height:100%;
        }
    float: left;
    padding:0px 5px;
    background-color: $blue;
    text-align: center;
    border-right: 1px #FFFFFF solid;
    @media print, screen and (max-width: 39.9987em){ // mobile
           &:nth-child(2), &:nth-child(4){
                border: none;
            }
        }
     @media print, screen and (min-width: 39.99875em){ // large desktop view
               border-right: 1px #FFFFFF solid;
        }
    &:last-child{
        border: none;
    }
    
}
div.video-slideimgtext p.slide-imgtext {
    width:inherit;
    height: 100%;
    font-size: 16px!important;
    padding-top: 7px;
    color: #fff;
    span{
        color:$yellow;
         font-family: "Karbon-Bold";
    }
}
a.showall {
    cursor: pointer;
}

.close-button.videopop{
    right:52px;
    top: 7rem;
    font-size: 3em;
    line-height: 2;
    font-family: "Karbon-Regular";
    color:#fff;
     @media print, screen and (min-width: 39.9987em){ // mobile
            right:5rem;
            top: 8rem;
            font-size: 5em;
        }
     @media print, screen and (min-width: 64em){ // large desktop view
               right:8rem;
               top: 1rem;
        }
    
}


/* ====================================================
    SweetAlert Modal
   ==================================================== */
.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, .75) !important;
}

.swal2-popup {
  width: 60em !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  padding-bottom: 35px !important;
  padding:1.25em 2em 2em !important;

  &.HCP_popup {
    padding-top: 5.5em !important;
    background-image:url('../img/logo_Remicade_blue-pop.svg');
    background-repeat: no-repeat;
    background-position: top 40px left 50px;
    background-size: 201px;
  }
}

.swal2-popup #swal2-content p{
  text-align:left !important;
    img {
      margin-bottom: 10px;
    }
}

.swal2-popup .swal2-title {
  color: #0077c0 !important;
    font-size: 40px !important;
    font-weight: 500 !important;
    margin: 10px 30px 10px !important;
    text-align:left !important;
}
.swal2-popup .swal2-content{
  font-size: 18px !important;
  color:#1E1E1E !important;
  line-height: 1.1em !important;
  margin: 0px 20px 0px !important;
  font-weight: normal !important;

}

.swal2-popup .swal2-actions {
  margin-top: 0px !important;
}

.swal2-popup .swal2-close, .swal2-popup .swal2-close:hover {
  -webkit-transform: none !important;
  transform: none !important;
  color: #969696 !important;
  font-weight:bold !important;
  box-shadow: none !important;
}

.swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel {
  border-radius: 0px !important;
  font-size: 17px !important;
    font-family: "Karbon-Medium" !important;
    background-color: #0077c0 !important;
    box-shadow: none !important;
    border: none !important;
    padding: 10px 20px 8px !important;
}


.swal2-popup .swal2-styled.swal2-cancel {
    background-color: #fff !important;
    color: #4D77C3 !important;
    box-shadow: none !important;
    margin-left: 40px;
}


.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
}

@media screen and (max-width: 767px) {

  .swal2-popup {
    padding:1.25em 1em 3em !important;

    &.HCP_popup {
      background-position: top 40px left 35px;
    }
  }

  .swal2-popup .swal2-title { font-size: 24px !important; }

  .sweet-alert p { font-size:18px; }

  .swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel{ width: 85%;}

  .swal2-popup .swal2-styled.swal2-cancel {
    margin-left:0px;
    margin-top: 20px;
  }

 }

/* ====================================================
   LEGAL, PRIVACY, CONTACT
   ==================================================== */

.legal_content{
    h5{
        margin-top: 35px;
        &.subheader{
            margin-top: -15px;
            margin-bottom: 15px;
            @media print, screen and (min-width: 64em){ // large desktop view
                margin-bottom: 35px;
            }
        }
    }
    
}



/* ====================================================
    important-safety-info
   ==================================================== */
.bold{font-family: "Karbon-Bold";}
.sbold{font-family: "Karbon-Semibold";}
.isi-header{
  background-color:$blue;
}

.isiexpand{
    position: absolute; 
    right:5%; 
    top:4%;
    width:23px;
    height:27px;
    background:url('../img/icon_isiplus.png') no-repeat;
    background-size:contain;
    background-position: left 50% top 5%;
    
}
.alignment-special {
  @media print, screen and (min-width: 64em){ 
    margin-top: -20px;
  }
}
.isi_ind_Txt{
     background-color: $white;

    .content_box{
        position: relative;
        padding: 3.2% 3.2% 10% 3.2%;
        @media print, screen and (min-width: 64em){ // large desktop view
          padding:5%;
        }

        p,li{
             line-height: 1.3em;
             margin-bottom: 10px;
        }
        h6{
            color:$blue;
        }
        .isi-last{
            position:relative;
           
            @media print, screen and (min-width: 64em){ // large desktop view
                 position: absolute;
                font-size: .9em;
                bottom:6.5%;
                right:-25px;

                &.rotate {
                    transform: rotate(-90deg);
                    /* Safari */
                    -webkit-transform: rotate(-90deg);
                    /* Firefox */
                    -moz-transform: rotate(-90deg);
                    /* IE */
                    -ms-transform: rotate(-90deg);
                    /* Opera */
                    -o-transform: rotate(-90deg);
                    /* Internet Explorer */
                    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);    
                  }
            }
        }
    }
}

.s_header{
        background-color: $blue;
        color:$white;
        font-family: "Karbon-Semibold";
        font-size: 1.2em;
        padding-left: 3%;
        padding-top: 3px;
        @media print, screen and (min-width: 64em){ // large desktop view
            font-size: 1.6em; 

            &.ind{   
                border-left: $white 1px solid;
                padding-left: 20px!important;
            }
                  
        }
 
}
.backtotop{
    position: absolute; 
    bottom:5px;
    right:5%;
    @media print, screen and (min-width: 64em){ // large desktop view
       bottom:3%;
       right:15%;   
    }   
}

.isi-btn {
  display:block;
  position:absolute;
  right:45px;
  top: 20px;
  z-index:200;
  width: 23px;
}

#section-isi{ 
    position: fixed; 
    height:25%;
    z-index:11;
    // width:100vw;
    background-color: $white;
    overflow-y: hidden;
    bottom: 0;
    right:0px;
    left:0px;

    // @media print, screen and (min-width: 64em){ // large desktop view
    //   width:100%;
    // }

    
    .sIND{      
      position: relative;
    }

    &.mob_isi{  
         max-height:100%;
         height:auto;
        
        &.open{ // mobile isi or ind open 
             top:0;
        }
        
       .content_box {
          padding-top: 10px;
          height: 10vh;
          
        }

        .s_header{
            position: relative;
            
            &:after{
            content: "+";
            font-size: 1.5em;
            position: absolute;
            //right: 65px;
            margin-top: -5px;
            color: $white;
            }
            &.isiOn:after  {
                   content:"-" ;
            }
            &.ind:after{right: 15px;}
            &.isi:after{
                right: 15px;}
                
         }
     
        .sISI, .sIND{
            background-color: $white;
        }
        
        .sIND{  
            position: absolute; 
            bottom:0px; 
            z-index: 1000;
            height:100px;
            
            &.On{
                height:80%;
                overflow-y: hidden;
                .content_box{
                    
                    height:85vh;
                   @media print, screen and (min-width: 39.999em){ // ipad view
                     height:80vh;
                   }
                   overflow-y: auto;
                }
            }
        }
        .sISI{
            height:25vh;
            &.On{
                height:83vh;
                overflow-y: hidden;
               
                .content_box{
                     height:81vh;
                    
                      overflow-y: auto;
                }
            }
        }
    
    }
}


.isi_content {
    .isi_ind_Txt {
      background-color: inherit;

      .s_header {
        display: none;
      }

      .content_box {
        padding: 0;

        .isi-last {
          position: inherit;
          right:inherit;
          bottom: inherit;

          &.rotate {
            transform: none;
            /* Safari */
            -webkit-transform: none;
            /* Firefox */
            -moz-transform: none;
            /* IE */
            -ms-transform: none;
            /* Opera */
            -o-transform: none;
            /* Internet Explorer */
            filter: none;    
          }

        }

      }
    }
}


/* ====================================================
    footer
   ==================================================== */
footer{
  background-color: $blue;

    a, a:hover {
      text-decoration: none;
    }
    .grid-container{
        padding: 8% ;
        
        color:$white;
        
         @media print, screen and (min-width: 64em){ // large desktop view
            padding-top: 2%;
            padding: 3% ;
            }
         p{
            margin-bottom: 6px;
        }
    }
    .carelogo{
        width:130px;
         @media print, screen and (min-width: 64em){ // ipad view
            width:175px;
            }
    
    }
    .jnjlogo{
        margin: 30px 0px;
        float: left;
         @media print, screen and (min-width: 39.999em){ // ipad view
              margin: 0px 0px;
              float: right;
                }
        @media print, screen and (min-width: 64em){ // ipad view
              margin: 0px 0px;
              float: right;
            }
    }
}


/* ====================================================
    popup overlay
   ==================================================== */

.reveal{
    
    width:92vw; 
    @media screen and (max-width: 39.99875em){
           height:auto;
           min-height: 400px;
           max-height: 90vh;
    }
    @media print, screen and (min-width: 64em){ // large desktop view
           height: auto;
           width: 80%;
           min-width: 800px;
           left:0;
            }
    .pop_content{
        overflow-y: auto;
        ul{
            li{
                list-style-type:disc;
                font-size: .8em;
                color:$light-blue;
                span{
                    color:$black;
                    font-size: 16px;
                    margin-top: 3px;
                }
            }
        }
        ol  {
          
            li{
                font-family: "Karbon-Bold";
                 color:$blue;
                span{
                    color:$black;
                }
                }
            
        }
        .foot{
            font-family: "Karbon-Regular"
        }
    }
    
   
    
}



